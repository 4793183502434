import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';

import ScrollToElement from '../util/ScrollToElement.js';



// import('./thumbnail_border.png');

// import { useTranslation } from 'react-i18next';

import "./home.css"
import "./image-gallery-burger.css"

export default function Home(){
    //i18n
    // const { t, i18n } = useTranslation('home/home');

    // const rhome1 = useRef(null);
    const rhome2 = useRef(null);
    const rhome3 = useRef(null);
    // const [curPageFocus, setCurPageFocus] = useState(rhome1);
    const [isIntersecting, setIsIntersecting] = useState(false);

    const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
    const [screenRatio, setScreenRatio] = useState(window.innerWidth / window.innerHeight)

    const [isAuto1, setAuto1] = useState(null);
    const [isAuto2, setAuto2] = useState(null);

    useEffect(() => {
        window.addEventListener("resize", () => {
            // const isportrait = window.innerWidth < window.innerHeight;
            // if (isportrait !== isPortrait) 
            setIsPortrait(window.innerWidth < window.innerHeight);
            setScreenRatio(window.innerWidth / window.innerHeight);
        }, false);
    }, [isPortrait]);

    const handleThumbnail1Click = (event, index) => {
        // console.log('Thumbnail clicked:', index);
        setAuto1(index);
        // console.log("set auto1: to false > " + isAuto1);
    };
    const handleThumbnail2Click = (event, index) => {
        // console.log('Thumbnail clicked:', index);
        setAuto2(index);
    };

    // useEffect(() => {
    //     console.log("isAuto1 updated:", isAuto1);
    // }, [isAuto1]);

    //test image gallery
    const images = [
        {
          original: './images/home/gallery1/S1.jpg',
          thumbnail: './images/home/gallery1/S1_sm.jpg',
        },
        {
          original: './images/home/gallery1/S2.jpg',
          thumbnail: './images/home/gallery1/S2_sm.jpg',
        },
        {
            original: './images/home/gallery1/S3.jpg',
            thumbnail: './images/home/gallery1/S3_sm.jpg',
        },
        {
            original: './images/home/gallery1/S4.jpg',
            thumbnail: './images/home/gallery1/S4_sm.jpg',
        },
        {
            original: './images/home/gallery1/S5.jpg',
            thumbnail: './images/home/gallery1/S5_sm.jpg',
        },
          
    ];

    const images2 = [
        {
            original: './images/home/gallery2/F1.jpg',
            thumbnail: './images/home/gallery2/F1_sm.jpg',
        },
        {
            original: './images/home/gallery2/F2.jpg',
            thumbnail: './images/home/gallery2/F2_sm.jpg',
        },
        {
            original: './images/home/gallery2/F3.jpg',
            thumbnail: './images/home/gallery2/F3_sm.jpg',
        },
        {
            original: './images/home/gallery2/F4.jpg',
            thumbnail: './images/home/gallery2/F4_sm.jpg',
        },
        {
            original: './images/home/gallery2/F5.jpg',
            thumbnail: './images/home/gallery2/F5_sm.jpg',
        },
          
    ];

    const myRenderRightNav = (onClick, disabled) => {
        return (
            <img src= {isPortrait ? './images/home/i_thumb_arrow_right.png' : './images/home/i_thumb_arrow_down.png' }
                className= { isPortrait ? 'image-gallery-right-nav m-0 p-0' : 'image-gallery-down-nav m-0 p-0' }
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    const myRenderLeftNav = (onClick, disabled) => {
        return (
            <img src={isPortrait ? './images/home/i_thumb_arrow_left.png' : './images/home/i_thumb_arrow_up.png' }
                className= {isPortrait ? 'image-gallery-left-nav m-0 p-0' : 'image-gallery-up-nav m-0 p-0' }
                disabled={disabled}
                onClick={onClick}
                aria-label='Next Slide'
            >

            </img>
        );
    };

    return(
        <div fluid class='home'>
            <div fluid class='mx-auto img-fixedbg img-fluid' style={{backgroundImage: 'url(' + require('../resource/bg01.jpg') + ')'}} />
            <div id='home_header' className='d-flex flex-row'>
                <img id='img_gmillion_logo' className='' src='./images/home/logo_gmillion.png' />
            </div>
            <Container fluid className='maxw-1920 p-0'>
                
                <div fluid id="home1" className={"homepart d-flex flex-row-reverse"}>
                    <div id='home1_div_all01' className={''}>
                        <img fluid id='all01' className={'img-fluid transi_ease-in-out'+ (screenRatio < 1.9 ? " srLow":"")}

                            style={ isPortrait ? {objectPosition: "38% 100%"} 
                                : screenRatio < 1.9 ? {objectPosition: "calc(" + ((1.90 - screenRatio) * 50) + "vw ) 100%"}
                                : {objectPosition: "0vw 100%"}
                            }
                            src='./images/home/all01_2.png' />
                    </div>
                    <div fluid id="home1_content" className={isPortrait?'content_over d-flex justify-content-center'
                                                                :'content_over d-flex justify-content-start'}>
                        <div fluid id='home1_content_inner' className={isPortrait? 'd-flex my-auto flex-column'
                                                                :'d-flex my-auto ms-0 flex-column'}>
                            <br/><br/>
                            <img fluid id="home1_logo_ilb" className={isPortrait? 'img-fluid m-auto ani_fade_in' 
                                                                : 'img-fluid ani_fade_in'}
                                src='./images/common/logo_iloveburger_xl.png' />
                            <div id='home1_t1' className='ms-5 ps-4'>
                                <span fluid id='t-home1-t1' className='h2 white transi_ease-in-out'>
                                    ทำร้าน & ทำฟาร์ม</span>
                            </div>
                            
                            <div fluid id='home1_content_lower' className='d-flex my-auto flex-column flex-column-reverse justify-content-end'>
                                {isPortrait ?
                                    <a id='l-home1-arrow-down' className='m-2 p-1' href='#home2'>
                                        <img fluid id='img-home1-arrow-down' className='ani_fade_in ani_movingdown_arrow' src='./images/home/01_arrow_down.png' />
                                    </a>
                                    :''}
                                <div fluid id='home1_dw_container' className={isPortrait? 'd-flex justify-content-center mx-auto p-1 mt-2'
                                                                    : 'd-flex justify-content-start'}>
                                    <img fluid id='home1_dw_alicia' className='img-fluid' src='/logo192.png' />
                                    <div id='home1_dw_link' className='my-auto ps-1 d-flex flex-column justify-content-center'>
                                        <Link fluid id='l_dw_google' className='m-auto' to='https://play.google.com/store/apps/details?id=io.gmillion.iloveburger'>
                                            <img fluid id='img_dw_google' className='img_dw_link img-fluid m-auto' src='./images/home/google_play.png'></img>
                                        </Link>
                                        <Link fluid id='l_dw_ios' className='m-auto' to='https://apps.apple.com/th/app/i-love-burger-%E0%B8%97%E0%B8%B3%E0%B8%A3-%E0%B8%B2%E0%B8%99-%E0%B8%97%E0%B8%B3%E0%B8%9F%E0%B8%B2%E0%B8%A3-%E0%B8%A1/id6450951515'>
                                            <img fluid id='img_dw_ios' className='img_dw_link img-fluid m-auto' src='./images/home/app_store.png'></img>
                                        </Link>
                                    </div>
                                    {isPortrait ? '':
                                        <Link id='l_play_lg' className='my-auto mx-3' to='https://youtu.be/n-2kAI08mY4'>
                                            <img id='img_dw_play' className='ani_fade_in dancing_burger' src='./images/home/01_play.png'></img>
                                        </Link>
                                    }
                                </div>
                                <div id='home1_t2' className='mt-5'>
                                    {isPortrait ?
                                        <span fluid id='t-home1-t' className='h2 white transi_ease-in-out'>
                                            ทำร้าน & ทำฟาร์ม<br /></span>
                                    :''}
                                    <span className='h1 white transi_ease-in-out'>ดาวน์โหลด<br />ได้แล้ววันนี้</span>
                                    
                                </div>
                                
                                {isPortrait ?
                                    <Link id='l_play_lg' className='my-auto mx-3' to='https://youtu.be/n-2kAI08mY4'>
                                        <img id='img_dw_play' className='ani_fade_in' src='./images/home/01_play.png'></img>
                                    </Link>
                                    :''
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div id='home4' className='homepart'>
                    <div className='gal_title_container' >
                        <p><img className='gal_title mx-auto' src='./images/home/p4_title.png'></img></p>
                    </div>
                    <div className='ImageGalleryContainer m-auto'>
                        <img id='p4_img1' src='./images/home/p4_img1.png'></img>
                        <img id='p4_img2' src='./images/home/p4_img2.png'></img>
                        <div id='p4_t1_container' className='gal_text_container mx-auto'>
                            <img id='p4_t1' className='gal_text_t1' src='./images/home/p4_t1.png'></img>
                        </div>
                    
                        {isAuto1 === null ?
                            <ImageGallery fluid id='igal1' 
                                items={images} 
                                thumbnailPosition = {isPortrait ? 'bottom' : 'left'}
                                showPlayButton = {false}
                                // showNav={false}
                                slideInterval= {2000}
                                autoPlay={true}
                                disableKeyDown = {true}
                                showFullscreenButton = {false}
                                renderRightNav = { myRenderRightNav }
                                renderLeftNav = { myRenderLeftNav }
                                onThumbnailClick = { handleThumbnail1Click }
                                className='mx-auto'
                            />
                        : 
                            <div><ImageGallery fluid id='igal3' 
                                items={images} 
                                thumbnailPosition = {isPortrait ? 'bottom' : 'left'}
                                showPlayButton = {false}
                                // showNav={false}
                                // slideInterval= {2000}
                                startIndex = {isAuto1}
                                autoPlay={false}
                                disableKeyDown = {true}
                                showFullscreenButton = {false}
                                renderRightNav = { myRenderRightNav }
                                renderLeftNav = { myRenderLeftNav }
                                // onThumbnailClick = { handleThumbnail1Click }
                                className='m-2'
                            /></div>
                        }  
                        {/* workaround for autoplay state change bug browser wont notice, so insert a div help */}
                    </div>
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div id="home5" className="homepart">
                    <div className='gal_title_container' >
                        <p><img className='gal_title mx-auto' src='./images/home/p5_title.png'></img></p>
                    </div>
                    <div className='ImageGalleryContainer m-auto'>
                        <img id='p5_img1' src='./images/home/p5_img1.png'></img>
                        <img id='p5_img2' src='./images/home/p5_img2.png'></img>
                        <div id='p5_t1_container' className='gal_text_container m-auto'>
                            <img id='p5_t1' className='gal_text_t1' src='./images/home/p5_t1.png'></img>
                        </div>
                    
                        {isAuto2 === null ?
                            <ImageGallery fluid id='igla2' 
                                items={images2} 
                                thumbnailPosition = {isPortrait ? 'bottom' : 'left'}
                                showPlayButton = {false}
                                // showNav={false}
                                slideInterval= {2000}
                                autoPlay={true}
                                disableKeyDown = {true}
                                showFullscreenButton = {false}
                                renderRightNav = { myRenderRightNav }
                                renderLeftNav = { myRenderLeftNav }
                                onThumbnailClick = { handleThumbnail2Click }
                                className='mx-auto'
                            />
                        :   <div><ImageGallery fluid id='igla2' 
                                items={images2} 
                                thumbnailPosition = {isPortrait ? 'bottom' : 'left'}
                                showPlayButton = {false}
                                // showNav={false}
                                // slideInterval= {2000}
                                startIndex = {isAuto2}
                                autoPlay={false}
                                disableKeyDown = {true}
                                showFullscreenButton = {false}
                                renderRightNav = { myRenderRightNav }
                                renderLeftNav = { myRenderLeftNav }
                                // onThumbnailClick = { handleThumbnail2Click }
                                className='mx-auto'
                            /></div>
                        }
                        
                    </div>
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div fluid id="home2" ref={rhome2} className="homepart d-flex m-1">
                    <div fluid className='home_s3_bg_container m-auto w-100'>
                        <img fluid class='home_s3_bg_img img-fluid' src='./images/home/all02.png' alt=''/>
                    </div>
                    <div fluid className='home_s3_content_over_flexx d-inline-flex justify-content-center m-auto'>
                        <div fluid className='home_s3_content_over_flexy d-flex m-auto'>
                            <img fluid class='home_s3_img_over img-fluid transi_ease-in-out' src='./images/home/all02_over.png' alt='Over 100 menus' />
                        </div>
                    </div>
                    
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                {/* <ScrollToElement /> */}
                <div id="home3" ref={rhome3} className="homepart d-flex m-1">
                    <div className='home_s3_bg_container m-auto w-100'>
                        <img fluid class='home_s3_bg_img img-fluid' src='./images/home/all03.png' alt=''/>
                    </div>
                    <div className='home_s3_content_over_flexx d-inline-flex justify-content-center m-auto'>
                        <div className='home_s3_content_over_flexy d-flex m-auto'>
                            <img fluid class='home_s3_img_over img-fluid transi_ease-in-out' src='./images/home/all03_over.png' alt='Over 100 menus' />
                        </div>
                    </div>
                </div>


                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div id="home6" className="homepart">
                    <div fluid className='home_s3_bg_container m-auto w-100'>
                        <img fluid class='home_s3_bg_img img-fluid mx-auto' src='./images/home/all06.png' alt=''/>
                    </div>
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div id="home7" className="homepart">
                    <div fluid className='home_s3_bg_container m-auto w-100'>
                        <img fluid class='home_s3_bg_img img-fluid' src='./images/home/all07.png' alt=''/>
                    </div>
                </div>

                <span className='h1 d-block'><p>&nbsp;</p></span>
                <div id="home8" className="homepart">
                    <div fluid className='home_s3_bg_container m-auto w-100'>
                        <img fluid class='home_s3_bg_img img-fluid' src='./images/home/all08.png' alt=''/>
                    </div>
                </div>

                <p>&nbsp;</p>
            </Container>
            
        </div>
    )

}
// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, Link, Navigate } from 'react-router-dom';

// import { withRouter } from 'react-router-dom';
// import ReactGA from 'react-ga'
// import GA4React from "ga-4-react";
import GA4React from 'react-ga4'
import ReactPixel from 'react-facebook-pixel';

import Header from './header/header.js';
import Header_shot from './header/header_shot.js';
import Footer from './footer/footer.js';
import Righter from './righter/righter.js';

import Home from './home/home.js';
import Coming_soon from './coming_soon/coming_soon.js';
import Privacy from './privacy/privacy';
import Tos from './tos/tos';
import Ticket from './ticket/ticket';

function App() {
  const location = useLocation();

  //init Google Ana 4 & meta pixel
  useEffect(() => {
    GA4React.initialize("G-MDW6K9QV9X");
    ReactPixel.init('579657030905899');
  }, []);
 
  //google pageview
  useEffect(() => {
    GA4React.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  //meta pageview
  useEffect(() => {
    const pagePath = location.pathname;
    const pageTitle = document.title;
    const customPageName = `${pagePath} - ${pageTitle}`;

    ReactPixel.pageView(customPageName);
  }, [location]);

 

  return (
    <div className="App">
      {/* <BrowserRouter> */}
        <Routes>
          <Route path='/coming_soon/' element={''} />
          <Route path='/privacy/' element={<Header_shot />} />
          <Route path='/tos/' element={<Header_shot />} />
          <Route path='/ticket/' element={<Header_shot />} />
          <Route path='*' element={<Header />} />
        </Routes>

        <Routes>
          <Route exact path='/home' element={<Righter />} />
        </Routes>
      
        <Routes>
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/coming_soon' element={<Coming_soon />} />
          <Route exact path='/privacy' element={<Privacy />} />
          <Route exact path='/tos' element={<Tos />} />
          <Route exact path='/ticket' element={<Ticket />} />

          <Route path='/' element={ <Navigate to="/home" /> }/>
          <Route path='/*' element={ <Navigate to="/home" /> }/>
        </Routes>

        

        <Routes>
          <Route path='/coming_soon/*' />
          <Route path="*" element={<Footer />} />
        </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;

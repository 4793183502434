import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import "./footer.css"

export default function Footer(){
    return(
        <div id='footer'>
            <p className='p-2'>
                <Link to='/privacy' className='ft_link p-2'><span id='ft_t_privacy'>privacy policy</span></Link>
                <Link to='/tos' className='ft_link p-2'><span id='ft_t_tos'>term of service</span></Link>
                {/* <Link to='/cookies' className='ft_link p-2'><span id='ft_t_cookies'>cookies policy</span></Link> */}
            </p>
            <div fluid id='logo_container' className='d-flex justify-content-center mx-auto ps-5 pe-5 pt-2'>
            
            <Link fluid id='l_gmillion' className='m-0 p-0' to='https://www.GMillion.io'>
                <img fluid id='img_gmillion' className='img-fluid mx-3' 
                    src='/images/footer/logo_gmillion.png'></img>
            </Link>
            <Link fluid id='l_sonew' className='m-0 p-0' to='http://www.sonew.co.kr/'>
                <img fluid id='img_sonew' className='img-fluid mx-3' 
                    src='/images/footer/logo_sonew.png'></img>
            </Link>
            </div>
            <div id='div_hr' className='mx-auto'>
                <hr />
            </div>
            <span id='t_copy'>© 2023 G MILLION Co., Ltd. All Rights Reserved.</span>
        </div>
    )
}
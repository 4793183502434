import React, { useRef, useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from "react-router-dom"

import "./righter.css";

export default function Righter(){
    const [hideattop, setHideAtTop] = useState('')
    const [showattop, setShowAtTop] = useState('showattop')
    const [navattop, setNavAtTop] = useState('navattop')
    const [margintop, setTopmargin] = useState('32px');
    const [toggle, setToggle] = useState(false);
    const [isToggle, setIsToggle] = useState('')

    const [isAtBottom, setAtBottom] = useState(false);
    const [footerHeight, setFooterHeight] = useState(0);
    const [footerY, setFooterY] = useState('');
    const [CurrentY, setCurrentY] = useState(0);

    //navbar scroll changing function
    const checkIsAtTop = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 32) {
            setHideAtTop('');
            setShowAtTop('');
            setNavAtTop('');
            setTopmargin('0px');
        } else {
            setHideAtTop('hideattop');
            setShowAtTop('showattop');
            setNavAtTop('navattop');
            setTopmargin(32 - window.scrollY + 'px');
        }
    }

    const testCheckY = () => {
        var fEle = document.getElementById('footer');
        if(fEle == null)return;

        var rEle = document.getElementById('BottomSpot');
        if(rEle == null)return;

        setFooterY(fEle.offsetTop);
        setCurrentY(window.scrollY + rEle.offsetTop + rEle.offsetHeight);
    }

    const checkAtButtom = () => {
        var fEle = document.getElementById('footer');
        if(fEle == null)return;

        var rEle = document.getElementById('rt_BottomSpot');
        if(rEle == null)return;

        
        // setFooterHeight(rEle.bottom);

        if(window.scrollY + rEle.offsetTop + rEle.offsetHeight >= fEle.offsetTop){
            setFooterHeight(window.scrollY + rEle.offsetTop + rEle.offsetHeight - fEle.offsetTop);
            setAtBottom('isAtbottom');
        }else{
            setFooterHeight(0);
            setAtBottom('');
        }
                
    }

    const doToggle = () => {
        setToggle(!toggle);
        if(!toggle){
            setIsToggle('isToggle');
        }else{
            setIsToggle('');
        }

    }

    useEffect(() => {
        checkIsAtTop();
        testCheckY();
        // adding the event when scroll change background
        window.addEventListener("scroll", checkIsAtTop);
        window.addEventListener("scroll", testCheckY);
        window.addEventListener("scroll", checkAtButtom);
    })

    return(
        <div id='righter' className='p-0 m-0'>
            <div fluid id='rt_social_container_outer' className='m-auto me-1 d-flex flex-column ani_fade_in'>
                <div fluid id='rt_social_container_inner' className='m-auto p-auto d-flex flex-column'>
                    {/* <a fluid href='/coming_soon' className='l_social m-1 transi_ease-in-out'><img fluid id='img_dc' className='img_fluid' src='./images/righter/i_dc_52.png' /></a> */}
                    <a fluid href='https://www.facebook.com/iloveburgerth' className='l_social m-1 transi_ease-in-out'><img fluid id='img_fb' className='img_fluid' src='./images/righter/i_fb_52.png' /></a>
                    {/* <a fluid href='https://www.instagram.com/iloveburgerth/' className='l_social m-1 transi_ease-in-out'><img fluid id='img_ig' className='img_fluid' src='./images/righter/i_ig_52.png' /></a> */}
                    <a fluid href='https://www.tiktok.com/@iloveburgerth' className='l_social m-1 transi_ease-in-out'><img fluid id='img_tt' className='img_fluid' src='./images/righter/i_tt_52.png' /></a>
                    {/* <a fluid href='https://twitter.com/iloveburgerthz' className='l_social m-1 transi_ease-in-out'><img fluid id='img_tw' className='img_fluid' src='./images/righter/i_tw_52.png' /></a> */}
                    <a fluid href='https://www.youtube.com/@gmillionofficial' className='l_social m-1 transi_ease-in-out'><img fluid id='img_yt' className='img_fluid' src='./images/righter/i_yt_52.png' /></a>
                </div>
            </div>

            {/* +  hideattop */}
            <div fluid id='rt_dw_container_outer' className={'m-0 me-5 p-3 ani_fade_in transi_opacity ' +  hideattop + ' ' + isAtBottom}
                style={{bottom: footerHeight + 'px'}}
            >
                {/* <span className='white'>cure: {CurrentY} : {footerHeight} : {footerY}-  </span> */}
                <div fluid id='rt_dw_container_inner' className='m-0 p-0 d-flex flex-column'>
                    <img fluid id='rt_alicia' className='img-fluid m-auto' src='/logo192.png'></img>
                    <Link to="/coming_soon" className='m-1 p-0'>
                        <img fluid id='rt_img_google' className='img_fluid m-0 p-0' src='./images/righter/i_dw_google.png' />
                    </Link>
                    <Link to="/coming_soon" className='m-1 mt-0 p-0'>
                        <img fluid id='rt_img_ios' className='img_fluid m-0 p-0' src='./images/righter/i_dw_ios.png' />
                    </Link>
                </div>
            </div>

            <div id='rt_BottomSpot'></div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from "react-router-dom"

import "./header.css";

export default function Header_shot(){
    // //i18n
    // const { t, i18n } = useTranslation('header/header');
    // // const [isth, setlang] = useState(true)

    // const changeLanguage = bt => {
    //     // setlang(!isth);
    //     if(i18n.language == 'th'){
    //         i18n.changeLanguage('en');
    //     }else{
    //         i18n.changeLanguage('th');
            
    //     }
    // }

    //navbar scroll when active state
    const [hideattop, setHideAtTop] = useState('')
    const [showattop, setShowAtTop] = useState('showattop')
    const [navattop, setNavAtTop] = useState('navattop')
    const [margintop, setTopmargin] = useState('32px');
    const [toggle, setToggle] = useState(false);
    const [isToggle, setIsToggle] = useState('')

    //navbar scroll changing function
    const checkIsAtTop = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 32) {
            setHideAtTop('');
            setShowAtTop('');
            setNavAtTop('');
            setTopmargin('0px');
        } else {
            setHideAtTop('hideattop');
            setShowAtTop('showattop');
            setNavAtTop('navattop');
            setTopmargin(32 - window.scrollY + 'px');
        }
    }

    const doToggle = () => {
        setToggle(!toggle);
        if(!toggle){
            setIsToggle('isToggle')
        }else{
            setIsToggle('');
        }

    }

    useEffect(() => {
        checkIsAtTop()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkIsAtTop)
    })

    return(
        <Navbar id='header' className={'p-0 d-flex justify-content-between ' + navattop + ' ' + isToggle} style={{marginTop: margintop}} expand='xl' sticky='top'>
            <Container fluid id='nav-container' className='container-fluid p-0 d-inline-flex justify-content-between '>
                <Navbar.Brand id="nav-brand" className="p-0 d-inline-flex justify-content-between">
                    <Link to="/home">
                        {/* <Image fluid id="img-logo-gmillion" className={"m-auto p-auto " + showattop} src='./images/header/logo_gmillion.png' alt="I LOVE BURGER" /> */}
                        <img fluid id="img-logo-burger" className={"m-auto p-auto " + isToggle + ' '} 
                        src='./images/header/logo_iloveburger.png' alt="I LOVE BURGER" />
                    </Link>
                </Navbar.Brand>
                {/* </div> */}
            </Container>
        </Navbar>
            
    )
}
import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from "react-router-dom"



import "./header.css";

export default function Header(){
    // //i18n
    // const { t, i18n } = useTranslation('header/header');
    // // const [isth, setlang] = useState(true)

    // const changeLanguage = bt => {
    //     // setlang(!isth);
    //     if(i18n.language == 'th'){
    //         i18n.changeLanguage('en');
    //     }else{
    //         i18n.changeLanguage('th');
            
    //     }
    // }

    const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);
    useEffect(() => {
        window.addEventListener("resize", () => {
            // const isportrait = window.innerWidth < window.innerHeight;
            // if (isportrait !== isPortrait) 
            setIsPortrait(window.innerWidth < window.innerHeight);
        }, false);
    }, [isPortrait]);

    //navbar scroll when active state
    const [hideattop, setHideAtTop] = useState('')
    const [showattop, setShowAtTop] = useState('showattop')
    const [navattop, setNavAtTop] = useState('navattop')
    const [margintop, setTopmargin] = useState('32px');
    const [toggle, setToggle] = useState(false);
    const [isToggle, setIsToggle] = useState('')
    const [isattop, setIsAtTop] = useState(true)

    //navbar scroll changing function
    const checkIsAtTop = () => {
        // console.log(window.scrollY)
        if (window.scrollY >= 32) {
            setHideAtTop('');
            setShowAtTop('');
            setNavAtTop('');
            setTopmargin('0px');
            setIsAtTop(false);
        } else {
            setHideAtTop('hideattop');
            setShowAtTop('showattop');
            setNavAtTop('navattop');
            setTopmargin(32 - window.scrollY + 'px');
            setIsAtTop(true);
        }
    }

    const doToggle = () => {
        setToggle(!toggle);
        if(!toggle){
            setIsToggle('isToggle')
        }else{
            setIsToggle('');
        }

    }

    useEffect(() => {
        checkIsAtTop()
        // adding the event when scroll change background
        window.addEventListener("scroll", checkIsAtTop)
    })

    return(
        <Navbar id='header' className={'p-0 d-flex justify-content-between ' + navattop + ' ' + isToggle} style={{marginTop: margintop}} collapseOnSelect expand='xl' sticky='top'>
            <Container fluid id='nav-container' className='maxw-1920 p-0 d-inline-flex justify-content-between '>
                <Navbar.Brand id="nav-brand" className="p-0 d-inline-flex justify-content-between">
                    <Link to="/home">
                        {/* <Image fluid id="img-logo-gmillion" className={"m-auto p-auto " + showattop} src='./images/header/logo_gmillion.png' alt="I LOVE BURGER" /> */}
                        <img fluid id="img-logo-burger" className={"m-auto p-auto " + isToggle + ' ' + navattop} 
                        src='/images/header/logo_iloveburger.png' alt="I LOVE BURGER" />
                    </Link>
                </Navbar.Brand>
                <div className=' d-none d-lg-block ms-auto'>
                    <div className='m-auto d-flex'>
                        <a href='#home1' className='navlinkitem'>หน้าหลัก</a>
                        <a href='#home2' className='navlinkitem'>ฟาร์ม</a>
                        <a href='#home4' className='navlinkitem'>ร้านเบอร์เกอร์</a>
                        <a href='#home6' className='navlinkitem'>พาร์ทไทม์</a>
                        <a href='#home7' className='navlinkitem'>แฟรนไชส์</a>
                        <Link to="/ticket" className='navlinkitem'>ติดต่อเรา</Link>
                        {/* <a href='#footer' className='navlinkitem'>ติดต่อเรา</a> */}
                    </div>
                </div>
                <div className='d-none d-lg-block m-auto'></div>
                <Navbar.Toggle id='nav_toggle' aria-controls="basic-navbar-nav" className='d-block d-lg-none' onClick={()=> doToggle()} >
                    <img fluid id='img-burger' className='m-auto dancing_burger' src='./images/header/burger.png'></img>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className=''>
                    
                    {/* Mobile potriat only Collapse Menu -full screen style-*/}
                    {isPortrait ?
                    <div id='nav_colp_mobile' className='d-block d-md-none' >
                        <div id='nav_colp_mobile_inner' className='mx-auto p-1'>
                            <img fluid id='img_colp_logo_mobile' className='img-fluid p-5 m-0' src='./images/common/logo_iloveburger_sm.png'></img>
                            <div id='nav_colp_mobile_inner2' className='mx-auto'>
                            <Nav.Link href='#home1' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>หน้าหลัก</Nav.Link>
                            <Nav.Link href='#home2' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>ฟาร์ม</Nav.Link>
                            <Nav.Link href='#home4' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>ร้านเบอร์เกอร์</Nav.Link >
                            <Nav.Link  href='#home6' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>พาร์ทไทม์</Nav.Link >
                            <Nav.Link  href='#home7' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>แฟรนไชส์</Nav.Link >
                            <Nav.Link  href='#footer' className='nav_colp_mobile_item ms-5 p-0 d-flex' onClick={()=> doToggle()}>ติดต่อเรา</Nav.Link >
                            </div>
                        </div>
                        <div id='nav_colp_social' className='pt-5'>
                            {/* <a fluid href='/coming_soon' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_discord.png'></img></a> */}
                            <a fluid href='https://www.facebook.com/iloveburgerth' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_facebook.png'></img></a>
                            {/* <a fluid href='https://www.instagram.com/iloveburgerth/' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_ig.png'></img></a> */}
                            <a fluid href='https://www.tiktok.com/@iloveburgerth' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_tiktok.png'></img></a>
                            {/* <a fluid href='https://twitter.com/iloveburgerthz' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_tweet.png'></img></a> */}
                            <a fluid href='https://www.youtube.com/@gmillionofficial' className=''>
                                <img className='nav_colp_scoial_icon' src='./images/header/i_32_youtube.png'></img></a>
                        </div>
                        <div className='mx-auto d-flex justify-content-center p-3'>
                            <Link to="https://play.google.com/store/apps/details?id=io.gmillion.iloveburger" className='mini-download ms-1 me-1'>
                                <img fluid height={'40px'} width={'137px'} className='ms-1 me-1' src='./images/header/google_small.png'></img> 
                            </Link>
                            <Link to="https://apps.apple.com/th/app/i-love-burger-%E0%B8%97%E0%B8%B3%E0%B8%A3-%E0%B8%B2%E0%B8%99-%E0%B8%97%E0%B8%B3%E0%B8%9F%E0%B8%B2%E0%B8%A3-%E0%B8%A1/id6450951515" className='mini-download ms-1 me-1'>
                                <img fluid height={'40px'} width={'137px'} className='ms-1 me-1' src='./images/header/ios_small.png'></img> 
                            </Link>
                        </div>
                    </div> : ''}

                    {/* Tablet/moblie landscape Collapse Menu -upper right style-*/}
                    <div id='nav_colp_tablet' className='d-block d-lg-none me-1 p-2' >
                        <div className='d-flex flex-column'>
                            <a href='#home1' className='navlinkitem'>หน้าหลัก</a>
                            <a href='#home2' className='navlinkitem'>ฟาร์ม</a>
                            <a href='#home4' className='navlinkitem'>ร้านเบอร์เกอร์</a>
                            <a href='#home6' className='navlinkitem'>พาร์ทไทม์</a>
                            <a href='#home7' className='navlinkitem'>แฟรนไชส์</a>
                            <a href='#footer' className='navlinkitem'>ติดต่อเรา</a>
                        </div>
                        {/* <NavDropdown.Item fluid className='navdropitem' eventKey={1.1} href="/home">หน้าหลัก</NavDropdown.Item> */}
                        <div className='d-flex mt-3'>
                            <img fluid className='' src='./images/header/logo_alicia_67.png' width={'67px'} height={'67px'}></img>
                            <div fluid className='d-flex flex-column'>
                                <Link to="https://play.google.com/store/apps/details?id=io.gmillion.iloveburger" className='mini-download ms-1'>
                                    <img fluid width={'102px'} className='' src='./images/header/google_small.png'></img> 
                                </Link>
                                <Link to="https://apps.apple.com/th/app/i-love-burger-%E0%B8%97%E0%B8%B3%E0%B8%A3-%E0%B8%B2%E0%B8%99-%E0%B8%97%E0%B8%B3%E0%B8%9F%E0%B8%B2%E0%B8%A3-%E0%B8%A1/id6450951515" className='mini-download mt-1 ms-1'>
                                    <img fluid width={'102px'} className='' src='./images/header/ios_small.png'></img> 
                                </Link>
                            </div>
                        </div>
                    </div>
                </Navbar.Collapse>
                {/* </div> */}
            </Container>
        </Navbar>
            
    )
}
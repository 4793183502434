import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import ImageGallery from 'react-image-gallery';
import axios from 'axios';



// import('./thumbnail_border.png');

// import { useTranslation } from 'react-i18next';

import "./privacy.css"



export default function Privacy(){
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        const fetchHtmlContent = async () => {
            try {
            const response = await axios.get('/privacy.html');
            setHtmlContent(response.data);
            } catch (error) {
            console.error('Error fetching HTML content:', error);
            }
        };
    
        fetchHtmlContent();
    }, []);
        

    return(
        <div id='privacy'>
            <div id='pv_gmil_header' className='header-gmil d-flex flex-row'>
                <img id='pv_img_logo_gmil' className='' src='./images/home/logo_gmillion.png' />
            </div>
            <div id='pp_content'> 
                <br/><br/>
                <div id='pp_content_wrapper' className='mx-auto' dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import "./coming_soon.css"

export default function Coming_soon(){
    return(
        <div id='coming_soon'>

            <div class='mx-auto img-fixedbg img-fluid' fluid style={{backgroundImage: 'url(' + require('./bg01.jpg') + ')'}} />

            <Link to='/home'>
                <div id='cs_header' className='d-flex flex-row m-auto p-auto'>
                    <img id='cs_img_gmillion_logo' className='img-fluid ms-0 my-auto' src='./images/home/logo_gmillion.png' />
                </div>
                <div  className="homepart d-flex flex-row-reverse">
                    <div id='cs_img_all01_container'>
                        <img fluid id='cs_img_all01' className='img-fluid transi_ease-in-out' src='./images/coming_soon/all01_2.png' />
                    </div>
                    <div fluid id='cs_div_white_bg' className='transi_opacity ani_fade_in'></div>
                    <div id="cs_content" className='content_over d-flex flex-column justify-content-center mx-auto'>
                        <div id='cs_content_inner' className='p-auto'>
                            <div fluid id='cs_logo_container' className='mx-auto'>
                                <Link to='/home'>
                                    <img fluid id='cs_img_logo' className='img-fluid mx-auto ani_fade_in' src='./images/common/logo_iloveburger_xl.png' />
                                </Link>
                            </div>
                            <div fluid id='cs_content_container' className='d-flex flex-column-reverse'>
                                <div fluid id='cs_coming_soon_container' className='mx-auto mt-3'>
                                    <img fluid id='cs_img_coming_soon' className='img-fluid m-auto p-auto ani_fade_in' 
                                        src='./images/coming_soon/coming_soon.png' />
                                </div>
                                <div id='cs_text_container' className='-auto'>
                                    <span id='cs_span1' className='brown46  ani_fade_in transi_ease-in-out'>เปิดให้เพื่อนๆ<br />ดาวน์โหลดได้เร็วๆนี้!</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </Link>
        </div>
    )
}